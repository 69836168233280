export default {
  invalidCharactersOnlyHyphenApostropheSpace: {
    id: 'address_fields_validator_invalid_characters_only_hyphen_apostrophe_space',
    defaultMessage:
      "The only special characters allowed are hyphen (-), apostrophe ('), and space.",
  },
  invalidInteger: {
    id: 'address_fields_validator_invalid_integer',
    defaultMessage: 'This field cannot contain numeric values',
  },
  streetInvalid: {
    id: 'address_fields_validator_street_invalid',
    defaultMessage: 'The street name contains invalid characters',
  },
  companyInvalid: {
    id: 'address_fields_validator_company_invalid',
    defaultMessage: 'The company name contains invalid characters',
  },
  addressFieldsValidatorZipExactLength: {
    id: 'address_fields_validator_zip_exact_length',
    defaultMessage: 'Enter a zipcode with {length} characters',
  },
  addressFieldsValidatorZipCodeDontMatch: {
    id: 'address_fields_validator_zip_code_dont_match',
    defaultMessage: 'Enter a ZIP Code that exists in this State',
  },
  addressFieldsValidatorPostalCodeUnauthorized: {
    id: 'address_fields_validator_postal_code_unauthorized',
    defaultMessage: 'Unfortunately we don’t deliver to this ZIP code',
  },
  addressFieldsValidatorCantContainPoBox: {
    id: 'address_fields_validator_cant_contain_po_box',
    defaultMessage: 'Unfortunately we don’t deliver to PO boxes.',
  },
}
